import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import {
  FaFacebookSquare,
  FaHourglassHalf,
  FaHome,
  FaMapMarkerAlt,
  FaPhone,
} from 'react-icons/fa';
import { Link } from 'gatsby';

import Form from './Form';

import hennessyCoffeeLogo from '../images/hennessy-coffee.png';
import cumminsAllisonLogo from '../images/cummins-allison.png';
import paltronicsAustraliaLogo from '../images/paltronics.png';
import ecashLogo from '../images/ecash.png';
import { GMBAIcon } from './vectors';

const ContactUs = ({ data }) => {
  return (
    <div
      id="contact"
      className="w-full px-8 py-12 mx-auto border-b-2 border-brand-blue max-w-7xl "
    >
      <div className="flex justify-center md:hidden">
        <Link to="/">
          <Image className="w-40 " fluid={data.mcgLogo.childImageSharp.fluid} />
        </Link>
      </div>

      <div className="flex flex-col-reverse lg:space-x-12 lg:flex-row">
        <div className="items-start justify-center w-full md:flex md:space-x-12 lg:w-1/2">
          <div className="justify-center hidden md:block">
            <Link to="/">
              <Image
                className="w-40 "
                fluid={data.mcgLogo.childImageSharp.fluid}
              />
            </Link>
          </div>

          <div className="w-full px-4 pt-5">
            <div className="mb-8">
              <h3 className="my-6 text-xl font-bold leading-none uppercase text-brand-blue md:mt-0">
                Contact Us
              </h3>
              <ul className="leading-none">
                <li className="mb-4">
                  <a className="flex items-center" href="tel:0429652211">
                    <FaPhone />
                    <span className="ml-4">
                      James Templeton: (04) 2965 2211
                    </span>
                  </a>
                </li>
                <li className="mb-4">
                  <a className="flex items-center" href="tel:0401344237">
                    <FaPhone />
                    <span className="ml-4">Dennis Warren: (04) 0134 4237</span>
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                    href={data.site.siteMetadata.googleMaps}
                  >
                    <FaMapMarkerAlt />
                    <span className="ml-4">
                      {data.site.siteMetadata.address}
                    </span>
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                    href={data.site.siteMetadata.facebook}
                  >
                    <FaFacebookSquare />
                    <span className="ml-4">Mid Coast Gaming</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mb-8">
              <h3 className="my-6 text-xl font-bold leading-none uppercase text-brand-blue">
                Hours
              </h3>
              <ul className="leading-none">
                <li className="flex items-center mb-4">
                  <FaHome />
                  <span className="ml-4">
                    Office Hours: Mon - Fri 7:30am - 5:00pm
                  </span>
                </li>
                <li className="flex items-center mb-4">
                  <FaHourglassHalf />
                  <span className="ml-4">
                    Business Hours: 24 hrs a day, 7 days a week
                  </span>
                </li>
              </ul>
            </div>
            <h3 className="my-6 text-xl font-bold leading-none uppercase text-brand-blue">
              Meet Our Partners
            </h3>
            <ul className="flex flex-wrap">
              {/* ecash */}
              <li className="flex items-center w-1/2 mb-4 ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.ecash.com.au"
                >
                  <img className="h-8" src={ecashLogo} alt="ecash logo." />
                </a>
              </li>
              {/* Paltronics Australia */}
              <li className="flex items-center w-1/2 mb-4 ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.paltronics.com.au"
                >
                  <img
                    className="h-10"
                    src={paltronicsAustraliaLogo}
                    alt="Paltronics Australia."
                  />
                </a>
              </li>
              {/* Cummins Allison */}
              <li className="flex items-center w-1/2 mb-4 ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cumminsallison.com.au/au/en"
                >
                  <img
                    className="h-12"
                    src={cumminsAllisonLogo}
                    alt="Cummins Allison."
                  />
                </a>
              </li>
              {/* Hennessy Coffee */}
              <li className="flex items-center w-1/2 mb-4 ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.hennessycoffee.com.au"
                >
                  <img
                    className="h-10"
                    src={hennessyCoffeeLogo}
                    alt="Hennessy Coffee."
                  />
                </a>
              </li>
              {/* GMBA */}
              <li className="flex items-center w-1/2 mb-4 ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gamingmachinebases.com.au/"
                >
                  <GMBAIcon className="h-8" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center mt-12 lg:items-start lg:px-8 lg:w-1/2 lg:mt-0">
          <h2 className="mb-8 text-xl font-bold leading-none text-center uppercase text-brand-blue lg:text-left">
            Get in touch
          </h2>
          <Form />
        </div>
      </div>
    </div>
  );
};

ContactUs.propTypes = {
  data: PropTypes.object,
};

export default ContactUs;
