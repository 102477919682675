import PropTypes from 'prop-types';
import React from 'react';

export function PaperPencilIcon({ className }) {
  return (
    <svg viewBox="0 0 87.3 77.6" fill="currentColor" className={className}>
      <path d="M76 19.5L86.4 8.6c.5-.6.5-1.4-.1-2L80.2.9c-.6-.5-1.4-.5-2 .1L64.3 15.7H16c0-3.8-3.1-7-7-7H7.7c-3.8 0-7 3.1-7 7v60c0 .8.6 1.4 1.4 1.4h72.2c.9 0 1.6-.7 1.6-1.6l.1-12.6V19.5zM3.5 74.3c0-2.3 1.9-4.2 4.2-4.2H9c2.3 0 4.2 1.9 4.2 4.2H3.5zm9.7-5.6c-1.2-.9-2.7-1.4-4.2-1.4H7.7c-1.5 0-3 .5-4.2 1.4v-53c0-2.3 1.9-4.2 4.2-4.2H9c2.3 0 4.2 1.9 4.2 4.2v53zM79.3 3.9l4.1 3.8-1.9 2.1L77.4 6l1.9-2.1zm-6.1 70.4H16V18.5h45.7l-5.4 6c-.1.1-.2.3-.2.4v.2l-1.7 8c-.2.8.3 1.5 1.1 1.6h.7l7.8-2.3h.2c.2-.1.3-.2.4-.3l8.7-9.6v51.8zm-15-46.2l2.5 2.3-3.2.9.7-3.2zm15-9.7l-9.8 10.8-4.1-3.8L75.5 8l4.1 3.8-6.4 6.6z" />
      <path d="M19.5 27.6v37.5c0 .7.6 1.3 1.3 1.3h12.1c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3H22.2V38.3c4.9-.6 8.7-4.4 9.3-9.3h6.8v10.7c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3V29h8.3v9.4c0 .7.6 1.3 1.3 1.3H67v10.7h-7.3c-.7 0-1.3.6-1.3 1.3S59 53 59.7 53H67v10.7H54.4v-5.4c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v5.4H41v-16h10.7v5.4c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3v-6.7c0-.7-.6-1.3-1.3-1.3H28.9c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h9.4v17.4c0 .7.6 1.3 1.3 1.3h28.8c.7 0 1.3-.6 1.3-1.3V38.4c0-.7-.6-1.3-1.3-1.3H52v-9.4c0-.7-.6-1.3-1.3-1.3H20.8c-.7-.1-1.3.5-1.3 1.2zm2.7 8V29h6.6c-.6 3.3-3.2 6-6.6 6.6z" />
    </svg>
  );
}

PaperPencilIcon.propTypes = {
  className: PropTypes.string,
};
