import React from 'react';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form
        action="/success/"
        className="w-full max-w-lg"
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        method="post"
        name="contact"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input name="form-name" type="hidden" value="contact" />
        <div hidden>
          <label htmlFor="bot-field">
            Don’t fill this out:{' '}
            <input
              id="bot-field"
              name="bot-field"
              onChange={this.handleChange}
            />
          </label>
        </div>

        {/* Name */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="name">
            <span className="sr-only">Name</span>
            <input
              className="w-full px-4 py-4 leading-tight text-gray-700 bg-white border border-gray-600 rounded appearance-none hover:bg-gray-100 focus:outline-none focus:shadow"
              id="name"
              name="name"
              onChange={this.handleChange}
              required
              type="text"
              placeholder="Name"
            />
          </label>
        </div>

        {/* Email address */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="email">
            <span className="sr-only">Email</span>
            <input
              className="w-full px-4 py-4 leading-tight text-gray-700 bg-white border border-gray-600 rounded appearance-none hover:bg-gray-100 focus:outline-none focus:shadow"
              id="email"
              name="email"
              onChange={this.handleChange}
              required
              type="email"
              placeholder="Email"
            />
          </label>
        </div>

        {/* Phone number */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="phone">
            <span className="sr-only">Phone</span>
            <input
              className="w-full px-4 py-4 leading-tight text-gray-700 bg-white border border-gray-600 rounded appearance-none hover:bg-gray-100 focus:outline-none focus:shadow"
              id="phone"
              name="phone"
              onChange={this.handleChange}
              type="text"
              placeholder="Phone"
            />
          </label>
        </div>

        {/* Message */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="message">
            <span className="sr-only">Message</span>
            <textarea
              className="w-full px-4 py-4 leading-tight text-gray-700 bg-white border border-gray-600 rounded appearance-none hover:bg-gray-100 focus:outline-none focus:shadow"
              id="message"
              name="message"
              onChange={this.handleChange}
              required
              rows={8}
              placeholder="Message...."
            />
          </label>
        </div>

        <div className="flex items-center justify-between mb-6">
          {/* Clear */}
          {/* <input
            className="px-4 py-2 mr-2 text-xs font-bold leading-none tracking-wide text-red-700 uppercase bg-red-100 rounded shadow cursor-pointer hover:bg-red-700 hover:shadow-md hover:text-white"
            onChange={this.handleChange}
            type="reset"
            value="Clear form"
          /> */}

          {/* Submit */}
          <button
            className="px-12 py-2 font-bold leading-none tracking-wide text-white uppercase bg-blue-700 rounded shadow cursor-pointer hover:bg-blue-600 hover:shadow-md"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}
