import PropTypes from 'prop-types';
import React from 'react';

export function CubeIcon({ className }) {
  return (
    <svg viewBox="0 0 83.5 83.8" fill="currentColor" className={className}>
      <path d="M13.6 2.5c0-.8-.7-1.5-1.5-1.5H2.4C1.6 1 .9 1.7.9 2.5v10c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5V4h8.4c.7 0 1.3-.7 1.3-1.5zm67.5-1.4h-9.8c-.8 0-1.5.7-1.5 1.5S70.5 4 71.3 4h8.4v8.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-10c-.1-.8-.8-1.4-1.6-1.4zM12.2 79.8H3.8v-8.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v10c0 .8.7 1.5 1.5 1.5h9.8c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.4-1.5zm68.9-10c-.8 0-1.5.7-1.5 1.5v8.5h-8.4c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5H81c.8 0 1.5-.7 1.5-1.5v-10c.1-.8-.6-1.5-1.4-1.5zm-3.7-8.3V22.3c0-.5-.3-1-.7-1.3L42.5 1.3c-.2-.1-.5-.2-.7-.2-.3 0-.5.1-.7.2L6.9 21c-.4.3-.7.7-.7 1.3v39.2c0 .5.3 1 .7 1.3L41 82.5c.4.3 1 .3 1.5 0l34.1-19.8c.6-.4.7-.6.8-1.2zM40.3 78.8L10.5 61.5l22.1-12.7c.7-.4.9-1.3.5-2-.2-.3-.5-.6-.9-.7-.4-.1-.8-.1-1.1.1L9.1 59V24.8l31.2 17.9v36.1zm1.5-38.6L10.5 22.3 41.8 4.2 73 22.3 41.8 40.2zM74.5 59L52.3 46.3c-.3-.2-.7-.2-1.1-.1-.4.1-.7.3-.9.7-.2.3-.2.7-.1 1.1.1.4.3.7.7.9L73 61.5 43.2 78.8v-36l31.2-17.9V59z" />
    </svg>
  );
}

CubeIcon.propTypes = {
  className: PropTypes.string,
};
